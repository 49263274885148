import React from "react";

export default function Sun() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      className="block dark:hidden"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.95012 2.35084C9.07913 2.57976 9.06164 2.86311 8.90544 3.07442C8.33597 3.84486 8.06193 4.79411 8.13318 5.74952C8.20442 6.70493 8.61622 7.60303 9.29367 8.28048C9.97113 8.95793 10.8692 9.36973 11.8246 9.44098C12.78 9.51223 13.7293 9.23819 14.4997 8.66871C14.711 8.51252 14.9944 8.49502 15.2233 8.62404C15.4522 8.75305 15.584 9.00451 15.5598 9.26617C15.4433 10.5271 14.9701 11.7287 14.1956 12.7305C13.4211 13.7322 12.3772 14.4927 11.1863 14.9229C9.99533 15.353 8.70649 15.4351 7.47058 15.1596C6.23467 14.884 5.1028 14.2621 4.20741 13.3667C3.31203 12.4714 2.69017 11.3395 2.41459 10.1036C2.13901 8.86766 2.22111 7.57882 2.65129 6.38787C3.08147 5.19691 3.84194 4.1531 4.8437 3.37858C5.84547 2.60405 7.0471 2.13084 8.30799 2.01431C8.56965 1.99013 8.8211 2.12191 8.95012 2.35084ZM7.10693 3.63753C6.58852 3.82509 6.09973 4.09284 5.65925 4.4334C4.85784 5.05302 4.24947 5.88807 3.90533 6.84083C3.56118 7.7936 3.4955 8.82467 3.71596 9.8134C3.93642 10.8021 4.43392 11.7076 5.15022 12.4239C5.86653 13.1402 6.77203 13.6377 7.76076 13.8582C8.74949 14.0787 9.78056 14.013 10.7333 13.6688C11.6861 13.3247 12.5211 12.7163 13.1408 11.9149C13.4813 11.4744 13.7491 10.9856 13.9366 10.4672C13.2345 10.7212 12.4815 10.827 11.7255 10.7706C10.4516 10.6756 9.25413 10.1266 8.35087 9.22329C7.4476 8.32002 6.89853 7.12255 6.80354 5.84867C6.74716 5.09269 6.85292 4.33961 7.10693 3.63753Z"
        class="fill-black dark:fill-white"
      />
    </svg>
  );
}
