import React from "react";

export default function Logo() {
  return (
    <svg width="53" height="21" viewBox="0 0 53 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 18.488V21.0003H48.817V18.488H0Z" fill="url(#paint0_linear_0_1)" />
      <path
        d="M23.9224 5.2723C24.2603 4.68119 25.0417 4.34342 26.2243 4.34342H30.068V6.94008H26.7101V15.7067H23.5V7.06675C23.5 6.32786 23.5845 5.86342 23.9224 5.2723Z"
        className="fill-black dark:fill-white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6335 4.28928V5.59984C18.8335 4.60026 17.6336 3.9783 16.0114 3.9783C13.056 3.9783 10.6117 6.53278 10.6117 9.8425C10.6117 13.1522 13.056 15.7067 16.0114 15.7067C17.6336 15.7067 18.8335 15.0847 19.6335 14.0852V15.3957H22.5V4.28928H19.6335ZM16.5447 12.9745C14.7893 12.9745 13.4782 11.7084 13.4782 9.8425C13.4782 7.97662 14.7893 6.71048 16.5447 6.71048C18.3224 6.71048 19.6335 7.97662 19.6335 9.8425C19.6335 11.7084 18.3224 12.9745 16.5447 12.9745Z"
        className="fill-black dark:fill-white"
      />
      <path
        d="M9.80382 7.48437L6.96319 7.65932C6.91463 7.41634 6.81023 7.19767 6.64999 7.00328C6.48975 6.80404 6.27852 6.64611 6.01631 6.52948C5.75895 6.40799 5.45061 6.34725 5.09128 6.34725C4.61056 6.34725 4.2051 6.44929 3.87491 6.65339C3.54472 6.85263 3.37962 7.11991 3.37962 7.45522C3.37962 7.72249 3.48645 7.94846 3.7001 8.13312C3.91376 8.31778 4.28037 8.466 4.79994 8.57777L6.8248 8.98597C7.91249 9.20951 8.7234 9.56911 9.25754 10.0648C9.79168 10.5605 10.0587 11.2116 10.0587 12.0183C10.0587 12.7521 9.84266 13.396 9.4105 13.95C8.98319 14.504 8.39564 14.9365 7.64785 15.2475C6.90492 15.5536 6.04787 15.7067 5.07672 15.7067C3.5957 15.7067 2.41575 15.3981 1.53685 14.781C0.662814 14.1589 0.150529 13.3134 0 12.2443L3.05186 12.0839C3.14412 12.5359 3.36748 12.8809 3.72195 13.119C4.07643 13.3523 4.53044 13.4689 5.084 13.4689C5.62785 13.4689 6.06487 13.3644 6.39506 13.1554C6.73011 12.9416 6.90006 12.6671 6.90492 12.3318C6.90006 12.0499 6.78109 11.8191 6.54802 11.6393C6.31494 11.4546 5.95561 11.3137 5.47003 11.2165L3.53258 10.8302C2.44003 10.6115 1.62669 10.2324 1.09255 9.69303C0.56327 9.15362 0.298631 8.466 0.298631 7.63016C0.298631 6.91095 0.492862 6.29136 0.881324 5.77139C1.27464 5.25142 1.82577 4.85051 2.53472 4.56865C3.24852 4.2868 4.08371 4.14588 5.0403 4.14588C6.45333 4.14588 7.5653 4.44474 8.37622 5.04246C9.19199 5.64018 9.66785 6.45415 9.80382 7.48437Z"
        className="fill-black dark:fill-white"
      />
      <path d="M34.2296 -0.000244141V3.14537H31V-0.000244141H34.2296Z" fill="url(#paint1_linear_0_1)" />
      <path d="M34.2296 4.39094V15.7067H31V4.39094H34.2296Z" className="fill-black dark:fill-white" />
      <path
        d="M38.7294 15.7065V9.30972C38.7294 7.78972 39.5371 6.79747 40.8329 6.79747C42.0016 6.79747 42.5967 7.59972 42.5967 9.20415V15.7065H45.8475V9.39415C45.8475 7.81078 46.591 6.79747 47.951 6.79747C49.1197 6.79747 49.7147 7.62078 49.7147 9.2464V15.7065H52.9656V8.88747C52.9656 5.99522 51.6905 4.17963 49.226 4.17963C47.5264 4.17963 46.3362 4.96075 45.6777 6.50189H45.4437C44.9128 4.96075 43.8289 4.17963 42.1715 4.17963C40.6417 4.17963 39.5585 4.93964 38.9207 6.48078H38.708V4.39074H35.5V15.7065H38.7294Z"
        className="fill-black dark:fill-white"
      />
      <defs>
        <linearGradient id="paint0_linear_0_1" x1="-7.3488" y1="18.488" x2="28.5086" y2="46.8431" gradientUnits="userSpaceOnUse">
          <stop stop-color="white" stop-opacity="0.86" />
          <stop offset="0.228" stop-color="#62A4F1" stop-opacity="0.79" />
          <stop offset="0.581089" stop-color="#7F4BEE" stop-opacity="0.77" />
          <stop offset="1" stop-color="#157FFB" stop-opacity="0.75" />
        </linearGradient>
        <linearGradient id="paint1_linear_0_1" x1="-7.97331" y1="-0.000242534" x2="54.0904" y2="8.5166" gradientUnits="userSpaceOnUse">
          <stop stop-color="white" stop-opacity="0.86" />
          <stop offset="0.228" stop-color="#62A4F1" stop-opacity="0.79" />
          <stop offset="0.581089" stop-color="#7F4BEE" stop-opacity="0.77" />
          <stop offset="1" stop-color="#157FFB" stop-opacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  );
}
